<template>
	<div>
		<section class="header-bg" style="">
		</section>
		<section class="container-1200" style="margin-top: 40px;">
			<div style="height: 20px;">
				<p class="title-hr"><strong style="font-size: 28px;font-family: 'Bebas Neue', cursive;">Membership &
						Fees</strong>
				</p>
			</div>
		</section>
		<section class="container-1200" style="margin-top: 40px;">
			<table>
				<tr style="background-color: lightyellow;">
					<th>Number of Journals</th>
					<th>Annual membership fee</th>
				</tr>
				<tr>
					<td> 1-9 journals </td>
					<td> USD1680,FREE!!(2022-2025) </td>

				</tr>
				<tr>

					<td> 10-30 journals </td>
					<td> USD3680，FREE!!(2022-2025) </td>
				</tr>

			</table>
		</section>
		<section class="container-1200" style="margin-top: 40px;">
			<h3>& &nbsp;APPLY NOW For <span style="color:orangered">Free Trail</span></h3>
			<p style="margin-top:10px;text-indent:30px;line-height:24px;font-size:16px;">To find out what Contrimetric can do for you, or to get a free Trail or quote, please take a moment to
				share a few details and one of our team members will be in touch.</p>
			<div class="applyContent" v-loading="loading">
				<el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="submitData">
					<el-form-item prop="name1"><el-input v-model="dataForm.name1"
							placeholder="First Name *"></el-input></el-form-item>
					<el-form-item prop="name2"><el-input v-model="dataForm.name2"
							placeholder="Last Name *"></el-input></el-form-item>
					<el-form-item prop="email">
						<el-input style="width:70%;" v-model="dataForm.email"
							placeholder="Work Email *"></el-input></el-form-item>
					<el-form-item prop="message" ><el-input style="margin-top:10px;" type="textarea" :autosize="{ minRows: 2, maxRows: 6}"
							placeholder="Message:" v-model="dataForm.message">
						</el-input></el-form-item>
					<el-checkbox v-model="dataForm.checked">Send me emails about Contrimetric Widget and updates</el-checkbox>
						<el-checkbox v-model="dataForm.checked1">Send me emails about products and updates for other parts of Contrimetric</el-checkbox>
				</el-form>
				<el-button class="button" @click="submitData">Submit</el-button>
			</div>

		</section>
	</div>
</template>

<script>
	export default {
		name: "price",
		data() {
			//自定义的邮箱和手机验证规则
			let checkEmail = (rule, value, callback) => { //验证邮箱
				const regEmail = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
				if (this.dataForm.email != '' && !regEmail.test(this.dataForm.email)) {
					callback(new Error('Please enter a valid email address'));
				}
			}
			return {
				dataForm: {
					name1: '',
					name2: '',
					email: '',
					message: '',
					checked: false,
					checked1:false
				},
				loading: false,
				dataRule: {
					email: [{
							required: true,
							message: 'Please enter your email address',
							trigger: 'blur'
						},
						{
							validator: checkEmail,
							trigger: "blur"
						}
					]
				},

			}
		},
		methods: {
			submitData() {
				this.loading = true
				$http.post("countJournalUniqueSourceTitle", this.dataForm).then(res => {
					// this.loading=false
				}).catch(err => {})
			}
		}
	}
</script>

<style lang="scss" scoped>
	table {
		border: 1px solid black;
		border-spacing: 0;
		border-collapse: collapse;
		text-align: center;
		font-size: 16px;
	}

	th,
	td {
		border: 1px solid black;
		padding: 20px;
	}

	.applyContent {
		width: 60%;
		margin: 10px;
	}

	.applyContent>.el-form .el-input {
		margin: 10px 0px;
	}

	.el-checkbox {
		margin: 10px 0px;
	}

	.button {
		display: block;
		margin: 0 auto;
		margin-top: 20px;
		background-color: rgb(29, 166, 184);
	}

	.el-form-item {
		margin-bottom: 10px;
	}
</style>